import React, { useState } from 'react';
import Header from './Header';
import '../styles/ProxyPurchasePage.css';
import { paymentService } from '../services/payment';

const ProxyPurchasePage = ({ type }) => {
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(30); // Default to 30 minutes

  const calculatePrice = () => {
    if (type === 'lte') {
      const priceMap = {
        5: 15,
        15: 12.5,
        30: 10
      };
      return (amount * priceMap[rotation]).toFixed(2);
    }
    return (amount * 1).toFixed(2); // $1/GB for residential
  };

  const handlePurchase = async () => {
    setLoading(true);
    try {
      const price = parseFloat(calculatePrice());
      await paymentService.createPayment(type, price, type === 'lte' ? rotation : null);
    } catch (error) {
      console.error('Purchase failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <Header />
      <div className="purchase-container">
        <h2 className="page-title">
          {type === 'lte' ? 'LTE Proxy Purchase' : 'Residential Proxy Purchase'}
        </h2>
        
        <div className="purchase-card">
          {type === 'lte' && (
            <div className="rotation-selector">
              <label>IP Rotation Interval:</label>
              <div className="rotation-options">
                <button 
                  className={`rotation-option ${rotation === 5 ? 'selected' : ''}`}
                  onClick={() => setRotation(5)}
                >
                  5 Minutes ($15/day)
                </button>
                <button 
                  className={`rotation-option ${rotation === 15 ? 'selected' : ''}`}
                  onClick={() => setRotation(15)}
                >
                  15 Minutes ($12.50/day)
                </button>
                <button 
                  className={`rotation-option ${rotation === 30 ? 'selected' : ''}`}
                  onClick={() => setRotation(30)}
                >
                  30 Minutes ($10/day)
                </button>
              </div>
            </div>
          )}

          <div className="amount-selector">
            <label>
              {type === 'lte' ? 'Days' : 'GB'} Amount:
              <span className="amount-value">{amount}</span>
            </label>
            <input
              type="range"
              min="1"
              max="100"
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value))}
              className="amount-slider"
            />
          </div>

          <div className="price-display">
            <span className="price-label">Total Price:</span>
            <span className="price-value">${calculatePrice()}</span>
          </div>

          <button 
            className="purchase-button"
            onClick={handlePurchase}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Purchase Now'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProxyPurchasePage; 