import React from 'react';
import '../styles/ProxyCard.css';

const ProxyCard = ({ type, data, onPurchase, onGenerateProxy }) => {
  const daysUntilExpiry = data.expiresAt ? 
    Math.max(0, Math.ceil((new Date(data.expiresAt) - new Date()) / (1000 * 60 * 60 * 24))) : 0;

  return (
    <div className="proxy-card">
      <div className="proxy-header">
        <h3>{type === 'lte' ? 'LTE Proxy' : 'Residential Proxy'}</h3>
        <div className="proxy-badge">
          {type === 'lte' ? 'Mobile IP' : 'Home IP'}
        </div>
      </div>
      
      <div className="proxy-stats">
        <div className="stat-item">
          <span className="stat-label">
            {type === 'lte' ? 'Days Left' : 'GB Left'}
          </span>
          <span className="stat-value">
            {type === 'lte' ? data.daysLeft : data.gbLeft}
          </span>
        </div>
        
        <div className="stat-item">
          <span className="stat-label">Status</span>
          <span className={`status-badge ${data.active ? 'active' : 'inactive'}`}>
            {data.active ? 'Active' : 'Inactive'}
          </span>
        </div>
      </div>

      <div className="proxy-details">
        <div className="detail-row">
          <span>IP Rotation</span>
          <span>{type === 'lte' ? 'Every 5-15 Minutes' : 'Every request'}</span>
        </div>
        <div className="detail-row">
          <span>Locations</span>
          <span>200+ Countries</span>
        </div>
        <div className="detail-row">
          <span>Threads</span>
          <span>Unlimited</span>
        </div>
      </div>

      {type === 'residential' && data.active && (
        <button 
          className="generate-button"
          onClick={onGenerateProxy}
        >
          Generate Proxy
        </button>
      )}
      
      <button 
        className="purchase-button"
        onClick={() => onPurchase(type)}
      >
        Purchase More
      </button>
    </div>
  );
};

export default ProxyCard;