import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Auth.css';

const EmailConfirmation = ({ email }) => {
  return (
    <div className="auth-card">
      <h2>Check your email</h2>
      <p className="auth-subtitle">
        We sent a confirmation link to<br />
        <strong>{email}</strong>
      </p>
      <p className="confirmation-text">
        Please check your email and click the confirmation link to complete your registration.
      </p>
      <div className="auth-footer">
        <Link to="/login" className="auth-button">
          Return to login
        </Link>
      </div>
    </div>
  );
};

export default EmailConfirmation;