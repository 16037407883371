// frontend/src/components/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabase';
import '../styles/Header.css';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      navigate('/login');
    }
  };

  return (
    <header className="header">
      <div className="header-container">
        <Link to="/" className="logo">
          DeathBy.Design
        </Link>
        <nav className="nav-links">
          <Link to="/lte-proxies" className="nav-link">LTE Proxies</Link>
          <Link to="/residential-proxies" className="nav-link">Residential Proxies</Link>
          <Link to="/profile" className="nav-link">Profile</Link>
          <Link to="/settings" className="nav-link">Settings</Link>
          <button onClick={handleLogout} className="cta-button">
            Logout
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;