import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import Header from '../components/Header';
import ProxyCard from '../components/ProxyCard';
import ProxyPurchaseModal from '../components/ProxyPurchaseModal';
import '../styles/DashboardPage.css';
import { paymentService } from '../services/payment';

const DashboardPage = () => {
  const [proxyData, setProxyData] = useState({
    lte: { daysLeft: 0, active: false },
    residential: { gbLeft: 0, active: false }
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    const fetchProxyData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user logged in');

        const { data, error } = await supabase
          .from('user_proxies')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;

        setProxyData({
          lte: { daysLeft: data.lte_days_left || 0, active: data.lte_days_left > 0 },
          residential: { gbLeft: data.residential_gb_left || 0, active: data.residential_gb_left > 0 }
        });
      } catch (error) {
        console.error('Error fetching proxy data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProxyData();
  }, []);

  const handlePurchase = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleConfirmPurchase = async (type, amount, rotation) => {
    try {
        await paymentService.createPayment(type, amount, rotation);
    } catch (error) {
        console.error('Purchase failed:', error);
    }
  };

  const handleGenerateProxy = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const response = await fetch('/api/proxy/credentials', {
        headers: {
          'Authorization': user.id
        }
      });

      if (!response.ok) throw new Error('Failed to generate proxy');
      
      const proxyData = await response.json();
      // Handle the proxy data as needed
    } catch (error) {
      console.error('Failed to generate proxy:', error);
    }
  };

  return (
    <div className="page-container">
      <Header />
      <div className="dashboard-container">
        <h2 className="page-title">Proxy Dashboard</h2>
        
        <div className="proxy-cards-grid">
          <ProxyCard type="lte" data={proxyData.lte} onPurchase={handlePurchase} />
          <ProxyCard type="residential" data={proxyData.residential} onPurchase={handlePurchase} />
        </div>
      </div>

      {showModal && (
        <ProxyPurchaseModal 
          type={modalType} 
          onClose={() => setShowModal(false)} 
          onPurchase={handleConfirmPurchase} 
        />
      )}
    </div>
  );
};

export default DashboardPage; 