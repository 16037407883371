import React, { useState } from 'react';
import '../styles/ProxyPurchaseModal.css';

const ProxyPurchaseModal = ({ type, onClose, onPurchase }) => {
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(30); // Default to 30 minutes

  const calculatePrice = () => {
    if (type === 'lte') {
      const priceMap = {
        5: 15,
        15: 12.5,
        30: 10
      };
      return (amount * priceMap[rotation]).toFixed(2);
    }
    return (amount * 1).toFixed(2); // $1/GB for residential
  };

  const handlePurchase = async () => {
    setLoading(true);
    try {
      const price = parseFloat(calculatePrice());
      console.log('Initiating purchase:', { type, price, rotation });
      await onPurchase(type, price, type === 'lte' ? rotation : null);
    } catch (error) {
      console.error('Purchase failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        
        <h2>Purchase {type === 'lte' ? 'LTE' : 'Residential'} Proxy</h2>
        
        {type === 'lte' && (
          <div className="rotation-selector">
            <label>IP Rotation Interval:</label>
            <div className="rotation-options">
              <button 
                className={`rotation-option ${rotation === 5 ? 'selected' : ''}`}
                onClick={() => setRotation(5)}
              >
                5 Minutes ($15/day)
              </button>
              <button 
                className={`rotation-option ${rotation === 15 ? 'selected' : ''}`}
                onClick={() => setRotation(15)}
              >
                15 Minutes ($12.50/day)
              </button>
              <button 
                className={`rotation-option ${rotation === 30 ? 'selected' : ''}`}
                onClick={() => setRotation(30)}
              >
                30 Minutes ($10/day)
              </button>
            </div>
          </div>
        )}

        <div className="amount-selector">
          <label>
            Select {type === 'lte' ? 'Days' : 'GB'}:
            <span className="amount-display">{amount}</span>
          </label>
          <input
            type="range"
            min="1"
            max="100"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
            className="amount-slider"
          />
        </div>

        <div className="price-display">
          <span>Total Price:</span>
          <span className="price">${calculatePrice()}</span>
        </div>

        <div className="purchase-actions">
          <button 
            className="cancel-button"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button 
            className="confirm-button"
            onClick={handlePurchase}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Confirm Purchase'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProxyPurchaseModal;