// frontend/src/pages/RegisterPage.js
import React, { useState } from 'react';
import { supabase } from '../supabase';
import { Link, useNavigate } from 'react-router-dom';
import { FiMail, FiLock, FiUser, FiEye, FiEyeOff } from 'react-icons/fi';
import '../styles/Auth.css';
import StyleSelectionModal from '../components/StyleSelectionModal';
import EmailConfirmation from '../components/EmailConfirmation';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { data: { user }, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username: username
          }
        }
      });

      if (signUpError) throw signUpError;

      if (user) {
        const { error: profileError } = await supabase
          .from('profiles')
          .insert([
            {
              id: user.id,
              username,
              email,
            },
          ]);

        if (profileError) throw profileError;
        setIsRegistered(true);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-header">
        <Link to="/" className="auth-logo">
          DeathBy.Design
        </Link>
      </div>
      <div className="auth-content">
        {isRegistered ? (
          <EmailConfirmation email={email} />
        ) : (
          <div className="auth-card">
            <h2>Create Account</h2>
            <p className="auth-subtitle">Get started with DeathBy.Design</p>
            
            <div className="form-group">
              <label className="form-label">Username</label>
              <input
                type="text"
                placeholder="Choose a username"
                className="form-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className="form-label">Password</label>
              <input
                type="password"
                placeholder="Create a password"
                className="form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button className="auth-button" onClick={handleRegister}>
              Create account
            </button>

            <div className="auth-footer">
              Already have an account?
              <Link to="/login" className="auth-link">Sign in</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterPage;